import { Slot } from "@radix-ui/react-slot";
import { cn } from "@watt/common";
import { type VariantProps, cva } from "class-variance-authority";
import React from "react";

const buttonVariants = cva(
  "inline-flex shrink-0 items-center justify-center rounded-md font-medium text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        none: "",
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        "outline-secondary":
          "border border-input border-secondary bg-background bg-secondary/10 text-secondary hover:border-secondary hover:bg-accent hover:text-accent-foreground hover:text-secondary",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        dialog:
          "absolute items-start rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 hover:ring-2 hover:ring-ring hover:ring-offset-2 disabled:cursor-not-allowed data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
      },
      size: {
        none: "",
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        smr: "h-9 rounded-full px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10"
      }
    },
    defaultVariants: {
      variant: "default",
      size: "default"
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
